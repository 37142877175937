<template>
    <div>
        <router-view v-if="$route.name === 'deviceView'"/>
        <div class="app-container" v-else>
            <el-form :model="form" label-width="80px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="区域">
                            <el-cascader v-model="regions" placeholder="请选择区域" :options="baseInfo.regionList"
                                         :props="treeProps" clearable
                                         @change="handleRegion"></el-cascader>
                        </el-form-item>
                    </el-col>
                    <el-col :span="16">
                        <el-form-item label="状态">
                            <el-select v-model="form.status" clearable>
                                <el-option :value="item.value" :label="item.label" v-for="item in baseInfo.statusList"
                                           :key="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所属公司">
                            <el-select v-model="form.companyId" :disabled="companyDisabled" clearable>
                                <el-option :value="item.id" :label="item.name" v-for="item in baseInfo.companyList"
                                           :key="item.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input v-model="form.keywords" placeholder="请输入设备编号/名称/位置"
                                      style="width: 200px;"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-button type="primary" @click="getDeviceList(1, 1)">查询</el-button>
                        <el-button @click="reset">重置</el-button>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item label="一级权限">
                            <div class="el-radio-button el-radio-button--small" @click="handleRoot('已配置')">
                                <span class="el-radio-button__inner"
                                      :class="{'active' : form.haveRoot === '已配置'}">已配置</span>
                            </div>
                            <el-badge :value="defaultTotal">
                                <div class="el-radio-button el-radio-button--small" @click="handleRoot('未配置')">
                                    <span class="el-radio-button__inner" :class="{'active' : form.haveRoot === '未配置'}">
                                        未配置
                                    </span>
                                </div>
                            </el-badge>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div style="margin-bottom: 20px;">
                    <el-button type="primary" @click="handleAdd" icon="el-icon-plus">新增设备</el-button>
                    <el-button @click="handleExport">导出列表</el-button>
                </div>
            </el-form>
            <el-table :data="deviceList" border style="width: 100%">
                <el-table-column prop="code" align="center" label="设备编号" width="200px"></el-table-column>
                <el-table-column prop="name" align="center" label="设备名称" width="200px"></el-table-column>
                <el-table-column prop="companyId" align="center" label="所属公司" width="200px"
                                 :formatter="companyFormatter"></el-table-column>
                <el-table-column prop="statusName" align="center" label="设备状态" width="100px"></el-table-column>
                <el-table-column prop="locationDesc" align="center" label="区域" width="200px"></el-table-column>
                <el-table-column prop="address" align="center" label="位置"></el-table-column>
                <el-table-column align="center" label="操作" fixed="right" width="350px">
                    <template slot-scope="scope">
                        <el-button type="primary" @click="goView(scope.row.id)">查看详情{{scope.row.key1}}</el-button>
                        <el-button type="primary" @click="handleEdit(scope.row.id)">修改</el-button>
                        <el-button type="success" @click="handleRelate(scope.row.id)">关联巡检人员</el-button>
                        <el-button type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background layout="total, prev, pager, next, jumper" :page-size="20" :total="deviceTotal"
                           :current-page="form.pageNum"
                           @current-change="currentChange" style="margin: 20px 0;"/>
        </div>
        <!-- 新增设备 -->
        <el-dialog title="新增设备" :visible.sync="addVisible" width="750px">
            <el-form ref="addForm" :model="addForm" inline label-width="120px" :rules="rules">
                <el-form-item label="设备编码:" prop="code">
                    <el-input v-model="addForm.code" placeholder="请输入设备编码" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="设备名称:" prop="name">
                    <el-input v-model="addForm.name" placeholder="请输入设备名称" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="所属公司:" prop="companyId">
                    <el-select v-model="addForm.companyId" placeholder="请选择所属公司" clearable style="width: 300px;">
                        <el-option :value="item.id" :label="item.name" v-for="item in baseInfo.companyList"
                                   :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区域:" prop="regionCode">
                    <el-cascader v-model="addRegions" placeholder="请选择设备所在区域" :options="baseInfo.regionList"
                                 :props="treeProps" clearable
                                 @change="handleAddRegion" style="width: 300px;"></el-cascader>
                </el-form-item>
                <el-form-item label="位置:" prop="address">
                    <el-input v-model="addForm.address" placeholder="请输入设备详细位置" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="坐标:">
                    <span style="padding-right: 10px;">经度</span>
                    <el-form-item label="" label-width="0px" prop="longitude" style="margin-bottom: 0;">
                        <el-input v-model="addForm.longitude" placeholder="请输入经度" style="width: 150px;"></el-input>
                    </el-form-item>
                    <span style="margin-left: 30px;padding-right: 10px;">纬度</span>
                    <el-form-item label="" label-width="0px" prop="latitude" style="margin-bottom: 0;">
                        <el-input v-model="addForm.latitude" placeholder="请输入纬度" style="width: 150px;"></el-input>
                    </el-form-item>
                    <a href="http://api.map.baidu.com/lbsapi/getpoint/index.html" target="_blank"
                       style="margin-left: 10px;color: #409eff;">拾取经纬度坐标</a>
                </el-form-item>
                <el-form-item label="A相传感器地址:" prop="phaseA">
                    <el-input v-model="addForm.phaseA" placeholder="请输入传感器地址" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="报警阈值:" prop="tempLimitA">
                    <el-input v-model="addForm.tempLimitA" placeholder="请输入传感器地址" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="B相传感器地址:" prop="phaseB">
                    <el-input v-model="addForm.phaseB" placeholder="请输入传感器地址" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="报警阈值:" prop="tempLimitB">
                    <el-input v-model="addForm.tempLimitB" placeholder="请输入传感器地址" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="C相传感器地址:" prop="phaseC">
                    <el-input v-model="addForm.phaseC" placeholder="请输入传感器地址" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="报警阈值:" prop="tempLimitC">
                    <el-input v-model="addForm.tempLimitC" placeholder="请输入传感器地址" style="width: 200px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleAddSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 修改设备 -->
        <el-dialog title="修改设备" :visible.sync="editVisible" width="750px">
            <el-form ref="editForm" :model="editForm" inline label-width="120px" :rules="rules">
                <el-form-item label="设备编码:" prop="code">
                    <el-input v-model="editForm.code" placeholder="请输入设备编码" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="设备名称:" prop="name">
                    <el-input v-model="editForm.name" placeholder="请输入设备名称" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="所属公司:" prop="companyId">
                    <el-select v-model="editForm.companyId" placeholder="请选择所属公司" clearable style="width: 300px;">
                        <el-option :value="item.id" :label="item.name" v-for="item in baseInfo.companyList"
                                   :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区域:" prop="regionCode">
                    <el-cascader v-model="editRegions" placeholder="请选择设备所在区域" :options="baseInfo.regionList"
                                 :props="treeProps" clearable
                                 @change="handleEditRegion" style="width: 300px;"></el-cascader>
                </el-form-item>
                <el-form-item label="位置:" prop="address">
                    <el-input v-model="editForm.address" placeholder="请输入设备详细位置" style="width: 300px;"></el-input>
                </el-form-item>
                <el-form-item label="坐标:">
                    <span style="padding-right: 10px;">经度</span>
                    <el-form-item label="" label-width="0px" prop="longitude" style="margin-bottom: 0;">
                        <el-input v-model="editForm.longitude" placeholder="请输入经度" style="width: 150px;"></el-input>
                    </el-form-item>
                    <span style="margin-left: 30px;padding-right: 10px;">纬度</span>
                    <el-form-item label="" label-width="0px" prop="latitude" style="margin-bottom: 0;">
                        <el-input v-model="editForm.latitude" placeholder="请输入纬度" style="width: 150px;"></el-input>
                    </el-form-item>
                    <a href="http://api.map.baidu.com/lbsapi/getpoint/index.html" target="_blank"
                       style="margin-left: 10px;color: #409eff;">拾取经纬度坐标</a>
                </el-form-item>
                <el-form-item label="A相传感器地址:" prop="phaseA">
                    <el-input v-model="editForm.phaseA" placeholder="请输入传感器地址" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="报警阈值:" prop="tempLimitA">
                    <el-input v-model="editForm.tempLimitA" placeholder="请输入报警阈值" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="B相传感器地址:" prop="phaseB">
                    <el-input v-model="editForm.phaseB" placeholder="请输入传感器地址" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="报警阈值:" prop="tempLimitB">
                    <el-input v-model="editForm.tempLimitB" placeholder="请输入报警阈值" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="C相传感器地址:" prop="phaseC">
                    <el-input v-model="editForm.phaseC" placeholder="请输入传感器地址" style="width: 200px;"></el-input>
                </el-form-item>
                <el-form-item label="报警阈值:" prop="tempLimitC">
                    <el-input v-model="editForm.tempLimitC" placeholder="请输入报警阈值" style="width: 200px;"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleEditSubmit">确 定</el-button>
            </div>
        </el-dialog>
        <!-- 关联设备 -->
        <el-dialog title="关联巡检人员" append-to-body :visible.sync="relateVisible" width="800px">
            <el-tabs v-model="cardName" @tab-click="getFrontUserBindList(1)">
                <el-tab-pane label="一级权限人员" name="1">
                    <div style="margin-bottom: 20px;">
                        <el-button type="primary" @click="handleAddStaff" icon="el-icon-plus">添加巡检人员
                        </el-button>
                    </div>
                    <el-table :data="firstList" border style="width: 100%">
                        <el-table-column type="index" align="center" width="80px"></el-table-column>
                        <el-table-column prop="name" align="center" label="姓名"></el-table-column>
                        <el-table-column prop="mobile" align="center" label="手机号码"></el-table-column>
                        <el-table-column align="center" label="操作" width="150px">
                            <template slot-scope="scope">
                                <el-button type="danger" @click="deleteStaff(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="二级权限人员" name="2">
                    <div style="margin-bottom: 20px;">
                        <el-button type="primary" @click="handleAddStaff" icon="el-icon-plus">添加巡检人员
                        </el-button>
                    </div>
                    <el-table :data="secondList" border style="width: 100%">
                        <el-table-column type="index" align="center" width="80px"></el-table-column>
                        <el-table-column prop="name" align="center" label="姓名"></el-table-column>
                        <el-table-column prop="mobile" align="center" label="手机号码"></el-table-column>
                        <el-table-column align="center" label="操作" width="150px">
                            <template slot-scope="scope">
                                <el-button type="danger" @click="deleteStaff(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <el-tab-pane label="三级权限人员" name="3">
                    <div style="margin-bottom: 20px;">
                        <el-button type="primary" @click="handleAddStaff" icon="el-icon-plus">添加巡检人员
                        </el-button>
                    </div>
                    <el-table :data="thirdList" border style="width: 100%">
                        <el-table-column type="index" align="center" width="80px"></el-table-column>
                        <el-table-column prop="name" align="center" label="姓名"></el-table-column>
                        <el-table-column prop="mobile" align="center" label="手机号码"></el-table-column>
                        <el-table-column align="center" label="操作" width="150px">
                            <template slot-scope="scope">
                                <el-button type="danger" @click="deleteStaff(scope.row.id)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
            <el-pagination background layout="total, prev, pager, next, jumper" :page-size="20" :total="staffTotal"
                           :current-page="staffPage"
                           @current-change="currentChangeStaff" style="margin: 20px 0;"/>
            <div slot="footer" class="dialog-footer">
                <el-button @click="relateVisible = false">关 闭</el-button>
            </div>
        </el-dialog>
        <!-- 添加巡检人员 -->
        <el-dialog title="添加巡检人员" append-to-body :visible.sync="addStaffVisible" width="600px">
            <el-form ref="addStaffForm" :model="addStaffForm" label-width="120px" :rules="addStaffRules">
                <el-form-item label="设备权限">
                    <el-select v-model="addStaffForm.rootLevel" placeholder="请选择设备权限" disabled clearable
                               style="width: 300px;">
                        <el-option :value="item.value" :label="item.label" :key="item.value"
                                   v-for="item in staffRoots"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="巡检人员" prop="userIdList">
                    <el-select :multiple="cardName !== '1'" v-model="addStaffForm.userIdList" placeholder="请选择巡检人员"
                               style="width: 300px;">
                        <el-option :value="item.id" :label="item.name" :key="item.id"
                                   v-for="item in unBindStaffs"></el-option>
                    </el-select>
                    <div style="font-size: 12px;color:#999;">人员权限等级设置以最新设定为准，已有权限将被覆盖</div>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addStaffVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleAddStaffSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
  addDeviceApi,
  delDeviceApi,
  delDeviceBindApi,
  deviceBindUserApi,
  deviceGetFrontUserListApi,
  exportDeviceApi,
  getDeviceDetailApi,
  getDeviceListApi,
  getDeviceSearchBaseApi,
  getFrontUserBindListPageApi,
  modifyDeviceApi
} from '@/api'

export default {
        name: 'device',
        data() {
            return {
                baseInfo: {
                    statuList: [],
                    regionList: [],
                    companyList: []
                },
                companyDisabled: false,
                regions: undefined,
                treeProps: {
                    expandTrigger: 'hover'
                },
                form: {
                    status: '',
                    companyId: '',
                    region: '',
                    keywords: '',
                    haveRoot: '已配置',
                    pageNum: 1
                },
                deviceList: [],
                deviceTotal: 0,
                defaultTotal: 0,
                addVisible: false,
                addRegions: undefined,
                addForm: {
                    code: undefined,
                    name: undefined,
                    companyId: undefined,
                    regionCode: undefined,
                    address: undefined,
                    longitude: undefined,
                    latitude: undefined,
                    phaseA: undefined,
                    tempLimitA: undefined,
                    phaseB: undefined,
                    tempLimitB: undefined,
                    phaseC: undefined,
                    tempLimitC: undefined
                },
                editVisible: false,
                editRegions: undefined,
                editForm: {
                    code: undefined,
                    name: undefined,
                    companyId: undefined,
                    regionCode: undefined,
                    address: undefined,
                    longitude: undefined,
                    latitude: undefined,
                    phaseA: undefined,
                    tempLimitA: undefined,
                    phaseB: undefined,
                    tempLimitB: undefined,
                    phaseC: undefined,
                    tempLimitC: undefined
                },
                rules: {
                    code: [
                        {required: true, message: '请输入设备编码', trigger: 'blur'}
                    ],
                    name: [
                        {required: true, message: '请输入设备名称', trigger: 'blur'}
                    ],
                    companyId: [
                        {required: true, message: '请选择所属公司', trigger: 'blur'}
                    ],
                    regionCode: [
                        {required: true, message: '请选择设备所在区域', trigger: 'blur'}
                    ],
                    address: [
                        {required: true, message: '请输入设备详细位置', trigger: 'blur'}
                    ],
                    longitude: [
                        {required: true, message: '请输入经度', trigger: 'blur'}
                    ],
                    latitude: [
                        {required: true, message: '请输入纬度', trigger: 'blur'}
                    ],
                    phaseA: [
                        {required: true, message: '请输入传感器地址', trigger: 'blur'}
                    ],
                    tempLimitA: [
                        {required: true, message: '请输入报警阈值', trigger: 'blur'}
                    ],
                    phaseB: [
                        {required: true, message: '请输入传感器地址', trigger: 'blur'}
                    ],
                    tempLimitB: [
                        {required: true, message: '请输入报警阈值', trigger: 'blur'}
                    ],
                    phaseC: [
                        {required: true, message: '请输入传感器地址', trigger: 'blur'}
                    ],
                    tempLimitC: [
                        {required: true, message: '请输入报警阈值', trigger: 'blur'}
                    ]
                },
                relateVisible: false,
                deviceId: '',
                cardName: '1',
                staffPage: 1,
                staffTotal: 0,
                firstList: [],
                secondList: [],
                thirdList: [],
                addStaffVisible: false,
                addStaffForm: {
                    deviceId: undefined,
                    rootLevel: undefined,
                    userIdList: []
                },
                unBindStaffs: [],
                staffRoots: [{
                    label: '一级权限',
                    value: '1'
                }, {
                    label: '二级权限',
                    value: '2'
                }, {
                    label: '三级权限',
                    value: '3'
                }],
                addStaffRules: {
                    userIdList: [
                        {required: true, message: '请选择巡检人员', trigger: 'blur'}
                    ]
                }
            }
        },
        mounted() {
            this.getBaseInfo()
            this.getDeviceList()
            this.getDefaultList()
        },
        methods: {
            getBaseInfo() {
                getDeviceSearchBaseApi().then(res => {
                    if (res) {
                        this.baseInfo = JSON.parse(JSON.stringify(res.data))
                        const userInfo = JSON.parse(localStorage.getItem('userInfo'))
                        // 判断用户是否为超管
                        if (userInfo['superAdmin'] != 1) {
                            this.companyDisabled = true
                            this.form.companyId = this.baseInfo.companyList[0]['id']
                        }
                    }
                })
            },
            currentChange(page) {
                this.form.pageNum = page
                this.getDeviceList()
            },
            getDeviceList(page, isBtn) {
                if (page) {
                    this.form.pageNum = 1
                }
                const data = JSON.parse(JSON.stringify(this.form))
                data['haveRoot'] = data['haveRoot'] === '已配置' ? 1 : 0
                getDeviceListApi(data).then(res => {
                    if (res) {
                        this.deviceList = res.data.list
                        this.deviceTotal = res.data.totalRow
                    }
                })
                if(isBtn) {
                    this.getDefaultList()
                }
            },
            getDefaultList() {
                const data = {
                    status: '',
                    companyId: '',
                    region: '',
                    keywords: '',
                    haveRoot: 0,
                    pageNum: 1
                }
                getDeviceListApi(data).then(res => {
                    if (res) {
                        this.defaultTotal = res.data.totalRow
                    }
                })
            },
            handleRoot(haveRoot) {
                this.form['haveRoot'] = haveRoot
                this.getDeviceList(1)
            },
            reset() {
                this.form['status'] = ''
                this.form['companyId'] = undefined
                this.form['region'] = undefined
                this.form['keywords'] = undefined
                this.form['haveRoot'] = '已配置'
                this.regions = []
                this.getDeviceList(1, 1)
            },
            handleRegion(e) {
                this.form['region'] = e[e.length - 1]
            },
            handleAddRegion(e) {
                this.addForm['regionCode'] = e[e.length - 1]
            },
            handleDelete(id) {
                this.$confirm('确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delDeviceApi({
                        id
                    }).then(res => {
                        if (res) {
                            this.$message.success('删除成功!')
                            this.getDeviceList(1)
                        }
                    })
                })
            },
            handleExport() {
                exportDeviceApi({
                    companyId: this.form.companyId,
                    keywords: this.form.keywords,
                    region: this.form.region,
                    status: this.form.status,
                    haveRoot: this.form.haveRoot === '已配置'? "1":"0"
                }).then(res => {
                    if (res) {
                        const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                        let link = document.createElement('a')
                        link.href = window.URL.createObjectURL(blob)
                        link.download = '设备管理数据导出'
                        link.click()
                    }
                })
            },
            handleAdd() {
                this.addVisible = true
                this.$refs['addForm'].resetFields()
            },
            handleAddSubmit() {
                this.$refs['addForm'].validate(valid => {
                    if (valid) {
                        addDeviceApi(this.addForm).then(res => {
                            if (res) {
                                this.$message.success('添加成功!')
                                this.getDeviceList()
                                this.addVisible = false
                            }
                        })
                    }
                })
            },
            handleEditRegion(e) {
                this.editForm['regionCode'] = e[e.length - 1]
            },
            handleEdit(deviceId) {
                getDeviceDetailApi({deviceId}).then(res => {
                    if (res) {
                        const base = JSON.parse(JSON.stringify(res.data.deviceBase))
                        this.editRegions = base.locationList
                        this.editForm = {
                            id: base.id,
                            code: base.code,
                            name: base.name,
                            companyId: base.companyId,
                            regionCode: base.location,
                            address: base.address,
                            longitude: base.longitude,
                            latitude: base.latitude,
                            phaseA: base.phaseA,
                            tempLimitA: base.limitA,
                            phaseB: base.phaseB,
                            tempLimitB: base.limitB,
                            phaseC: base.phaseC,
                            tempLimitC: base.limitC
                        }
                        this.editVisible = true
                    }
                })
            },
            handleEditClose() {
                this.$refs['editForm'].resetFields()
                this.editVisible = false
            },
            handleEditSubmit() {
                this.$refs['editForm'].validate(valid => {
                    if (valid) {
                        modifyDeviceApi(this.editForm).then(res => {
                            if (res) {
                                this.$message.success('修改成功!')
                                this.getDeviceList()
                                this.editVisible = false
                            }
                        })
                    }
                })
            },
            goView(id) {
                this.$router.push('/device/view/' + id)
            },
            viewClose() {
                this.viewVisible = false
            },
            companyFormatter(row) {
                return this.baseInfo.companyList.length > 0 ? this.baseInfo.companyList.filter(item => item.id === row.companyId)[0]['name'] : '-'
            },
            handleRelate(deviceId, cardName, page) {
                if (deviceId) {
                    this.deviceId = deviceId
                }
                if (cardName) {
                    this.cardName = cardName
                } else {
                    this.cardName = '1'
                }
                this.relateVisible = true
                this.getFrontUserBindList(page)
            },
            currentChangeStaff(page) {
                this.staffPage = page
                this.getFrontUserBindList()
            },
            getFrontUserBindList(page) {
                if (page) {
                    this.staffPage = 1
                }
                getFrontUserBindListPageApi({
                    deviceId: this.deviceId,
                    pageNum: this.staffPage,
                    rootLevel: this.cardName
                }).then(res => {
                    if (res) {
                        switch (this.cardName) {
                            case '1':
                                this.firstList = res.data.list
                                break
                            case '2':
                                this.secondList = res.data.list
                                break
                            case '3':
                                this.thirdList = res.data.list
                                break
                            default:
                                break
                        }
                        this.staffTotal = res.data.totalPage
                    }
                })
            },
            deleteStaff(id) {
                this.$confirm('确认删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    delDeviceBindApi({id}).then(res => {
                        if (res) {
                            this.$message.success('删除成功!')
                            this.handleRelate(this.deviceId, this.cardName, 1)
                        }
                    })
                })
            },
            handleAddStaff() {
                this.addStaffVisible = true
                this.addStaffForm['rootLevel'] = this.cardName
                this.addStaffForm['deviceId'] = this.deviceId
                this.addStaffForm['userIdList'] = []
                deviceGetFrontUserListApi({
                    deviceId: this.deviceId
                }).then(res => {
                    if (res) {
                        this.unBindStaffs = res.data
                    }
                })
            },
            handleAddStaffSubmit() {
                this.$refs['addStaffForm'].validate(valid => {
                    if (valid) {
                        const data = JSON.parse(JSON.stringify(this.addStaffForm))
                        if (this.cardName === '1') {
                            data['userIdList'] = [data['userIdList']]
                        }
                        deviceBindUserApi(data).then(res => {
                            if (res) {
                                this.$message.success('添加成功!')
                                this.handleRelate(this.deviceId, this.cardName)
                                this.addStaffVisible = false
                            }
                        })
                    }
                })
            }
        }
    }
</script>

<style scoped>
    .el-radio-button__inner.active {
        color: #FFF;
        background-color: #409EFF;
        border-color: #409EFF;
    }
</style>
